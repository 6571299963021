import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import useDateFns from '@hooks/useDateFns'

import queryString from 'query-string'

import Button from '@objects/button'
import Filter from './filter'

const StyledNewsItem = styled(Link).attrs({
  className: 'flex justify-between items-start pb-5 mb-5',
})`
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-light']?.default};

  .headline {
    ${tw`
      font-medium text-lg mr-11
    `}
  }
  .date {
    color: ${({ theme }) => theme.colors.black['650']};
  }
`

function NewsList({
  className,
  items,
  categoryfilter,
  filterAllLabel,
  showmore,
  initialshow,
  loadpershowmore,
  showmoretext,
}) {
  const location = useLocation()
  const {
    formatDistanceToNow,
    differeneInDaysFromNow,
    formatDate,
  } = useDateFns()

  const fullteaserlist = items.map((item) => {
    item.filterId = item.tag?.toLowerCase().replace(/ /g, '')
    return item
  })
  const categoryfilterWithId =
    categoryfilter?.map((cat) => {
      cat.filterId = cat.title?.toLowerCase().replace(/ /g, '') || ''
      return cat
    }) || []
  const filterAllId = filterAllLabel.desktop.toLowerCase().replace(/ /g, '')

  const [currentFilter, setCurrentFilter] = useState(filterAllId)
  const [filteredTeaserList, setFilteredTeaserList] = useState(fullteaserlist)
  const [loadnumber, setLoadnumber] = useState(initialshow || 500)
  const [hideloadmore, setHideloadmore] = useState(false)

  useEffect(() => {
    setFilteredTeaserList(
      items.filter(
        (t) =>
          !categoryfilter ||
          currentFilter === filterAllId ||
          currentFilter === t.filterId
      )
    )

    if (!!categoryfilter?.length) {
      navigate(
        `${location.pathname}#${queryString.stringify({
          articlefilter: currentFilter,
        })}`,
        {
          replace: true,
          state: {
            preventScroll: true,
          },
        }
      )
    }
  }, [currentFilter])

  useEffect(() => {
    const filter = queryString.parse(location.hash).articlefilter
    const valid = categoryfilterWithId?.find((cat) => cat.filterId === filter)

    if (filter && valid && filter !== currentFilter) {
      setCurrentFilter(filter)
    }
  }, [location.hash])

  function calcDateTime(date) {
    if (differeneInDaysFromNow(date) <= 7) {
      return formatDistanceToNow(date)
    } else {
      return formatDate(date)
    }
  }

  function renderList() {
    let result = []

    let rowIndex = 0
    let rows = 0

    for (let i = 0; i < filteredTeaserList.length; i++) {
      const newsItem = filteredTeaserList[i]
      if (rowIndex >= 1) {
        rows++
        rowIndex = 0
      }

      // break if showmore
      if (showmore && rows === loadnumber) {
        break
      }

      rowIndex += 2
      result.push(
        <StyledNewsItem key={i} to={newsItem.link}>
          <div className="headline">
            {newsItem.headline}
            <Button textlink />
          </div>
          <div className="date">{calcDateTime(newsItem.date)}</div>
        </StyledNewsItem>
      )
    }

    if (showmore) {
      if (!hideloadmore && result.length >= filteredTeaserList.length) {
        setHideloadmore(true)
      } else if (hideloadmore && result.length < filteredTeaserList.length) {
        setHideloadmore(false)
      }
    }

    return result
  }

  function onLoadMoreClick() {
    setLoadnumber(loadnumber + loadpershowmore)
  }

  return (
    <div className={'md:flex'}>
      {categoryfilter && (
        <div className={'md:w-1/3 md:pr-1/12'}>
          <Filter
            filteritems={categoryfilterWithId}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            filterAll={{
              label: filterAllLabel,
              id: filterAllId,
            }}
          />
        </div>
      )}
      <div
        data-testid="list"
        className={categoryfilter ? 'md:w-2/3' : 'md:w-full'}
      >
        {renderList()}
        {showmore && !hideloadmore && (
          <div className="text-center mt-8 sm:mt-9">
            <Button showmore onClick={onLoadMoreClick}>
              {showmoretext}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

NewsList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      date: PropTypes.string,
      tag: PropTypes.string,
    })
  ).isRequired,
  categoryfilter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  filterAllLabel: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
  showmore: PropTypes.bool,
  initialshow: PropTypes.number,
  loadpershowmore: PropTypes.number,
  showmoretext: PropTypes.string,
}

NewsList.defaultProps = {
  filterAllLabel: {
    mobile: 'Alle Kategorien',
    desktop: 'Alle',
  },
}

export default NewsList
