import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from 'gatsby'

import Headline from '@objects/headline'
import { TeaserCopy } from '@objects/copy'
import Button from '@objects/button'
import Icon from '@objects/icon'

const StyledIcon = styled.div.attrs({
  className: 'text-2xl text-black bg-yellow mr-2 md:mt-2 p-3',
})`
  border-radius: 50%;
  line-height: 1;
  font-size: 30px;

  svg {
    max-width: initial;
  }
`

function IconTeaser({ className, link, icon, headline, copy }) {
  function renderIcon() {
    switch (icon) {
      case 'faq':
        return <Icon name="FAQ" />
      case 'docs':
        return <Icon name="Download" />
      case 'media':
        return <Icon name="Media" />
      default:
        return <Icon name="FAQ" />
    }
  }

  return (
    <Link className={className} to={link} data-testid="teaser">
      <StyledIcon>{renderIcon()}</StyledIcon>
      <div>
        <Headline
          level={3}
          className="mb-1 text-lg font-medium leading-4"
          html={headline}
          ariaLabel={headline}
        />
        <TeaserCopy truncateextend={<Button textlink />} html={copy} />
      </div>
    </Link>
  )
}

IconTeaser.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['faq', 'docs', 'media']),
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string,
}

export default styled(IconTeaser).attrs({
  className: 'flex items-center md:items-start',
})`
  &:hover {
  }
`
