import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import tw from 'twin.macro'
import styled from 'styled-components'
import StickyBox from 'react-sticky-box'
import { FormattedMessage } from 'react-intl'

import Select from '@objects/select'

const StyledFilter = styled.div.attrs({
  className: 'hidden md:block',
})`
  .item {
    cursor: pointer;
    ${tw`
      mb-4 text-lg leading-5
    `}
    &.active {
      color: ${({ theme }) => theme.colors.red.default};
    }
  }
`

function Filter({
  className,
  filteritems,
  currentFilter,
  setCurrentFilter,
  filterAll,
}) {
  const itemAllMobile = {
    value: filterAll.id,
    label: filterAll.label.mobile,
  }

  function filterButtonClick(name) {
    setCurrentFilter(name)
  }

  function renderButton(id, name) {
    return (
      <div
        key={id}
        className={clsx('item', {
          active: currentFilter === id,
        })}
        onClick={() => filterButtonClick(id)}
      >
        {name}
      </div>
    )
  }

  function renderFilterButtons() {
    return [renderButton(filterAll.id, filterAll.label.desktop)].concat(
      filteritems.map((item) => renderButton(item.filterId, item.title))
    )
  }

  function changeEvent(selected) {
    filterButtonClick(selected.value)
  }

  return (
    <>
      <StickyBox className="hidden md:block" offsetTop={20} offsetBottom={20}>
        <StyledFilter>{renderFilterButtons()}</StyledFilter>
      </StickyBox>
      <div className="mb-8 md:hidden">
        <p className="text-lg text-center mb-3 font-medium">
          <FormattedMessage id="label.filter" />
        </p>
        <Select
          options={[itemAllMobile].concat(
            filteritems.map((item) => {
              return {
                value: item.filterId,
                label: item.title,
              }
            })
          )}
          defaultValue={itemAllMobile}
          onChange={changeEvent}
        />
      </div>
    </>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  filteritems: PropTypes.array.isRequired,
  currentFilter: PropTypes.string.isRequired,
  setCurrentFilter: PropTypes.func.isRequired,
  filterAll: PropTypes.object,
}

export default Filter
