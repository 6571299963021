import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import useStaticModule from '@hooks/useStaticModule'
import Headline from '@objects/headline'
import Stage from '@components/stage'
import Container from '@objects/container'
import TeaserCardList from '@components/teaserCardList'
import NewsList from '@components/newsList'
import IconTeaser from '@objects/iconTeaser'

function Newsroom({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()

  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const newsFilter = get(data, 'allContentfulArticleCategory.nodes')

  const heroItem = ((hero) => {
    return hero && hero.stageImage
      ? {
          image: hero.stageImage,
          headline: hero.title,
          copy: hero.teaserCopy?.childMarkdownRemark?.html,
          date: hero.createdAt,
          link: hero.slug,
          tag: hero.category?.title,
        }
      : null
  })(get(data, 'contentfulArticleList.articleHero'))

  const newsItems = get(data, 'contentfulArticleList')
    ?.articles.sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
    .map((item) => ({
      image: item.image?.fluid,
      headline: item.title,
      copy: item.teaserCopy?.childMarkdownRemark?.html,
      tag: item.category?.title,
      date: item.createdAt,
      link: item.slug,
    }))

  const events = get(data, 'contentfulEventList.events')
    ?.slice(0, 3)
    .map((item) => ({
      id: item.id,
      type: 'event',
      image: item.teaserImage?.fluid,
      headline: item.title,
      copy: item.copy?.childMarkdownRemark?.html,
      duration: {
        start: item.dateTimeStart || item.createdAt,
        end: item.dateTimeEnd,
      },
      location: item.shortLocation,
      link: item.slug,
    }))

  return (
    <>
      <Container nopadding>
        <Headline
          level={1}
          className="my-8 py-1 text-3xl md:text-5xl"
          ariaLabel={intl.formatMessage({ id: 'news.headline' })}
        >
          {intl.formatMessage({ id: 'news.headline' })}
        </Headline>
      </Container>

      {/* ToDo: icon teaser */}

      {heroItem && heroItem.image && (
        <Stage
          className="sm:mb-12 mb-8.5"
          type="infocard"
          image={{ ...heroItem.image, desktopAspectRatio: 2.38 }}
          infocard={{
            headline: heroItem.headline,
            copy: heroItem.copy,
            button: {
              label: intl.formatMessage({ id: 'button.getmore' }),
              link: heroItem.link,
            },
            date: heroItem.date,
            dateRelative: true,
            tag: {
              name: heroItem.tag,
              to: intl.formatMessage({ id: 'path.newsroom' }),
              filter: heroItem.tag,
            },
            truncate: 25,
          }}
          options={{ hideScrollIcon: true, bottomline: true }}
        />
      )}

      {newsItems?.length > 1 && (
        <Container className="mb-14" nopadding>
          <Headline
            level={3}
            className="mb-6"
            ariaLabel={intl.formatMessage({ id: 'news.list.headline' })}
          >
            {intl.formatMessage({ id: 'news.list.headline' })}
          </Headline>
          <NewsList
            showmore
            initialshow={10}
            loadpershowmore={10}
            showmoretext={intl.formatMessage({ id: 'button.loadmore' })}
            items={newsItems}
            categoryfilter={newsFilter}
          />
        </Container>
      )}

      {/* ToDo: icon teaser */}
      <Container className="mb-11 md:mb-14" nopadding>
        <Headline
          level={3}
          className="mb-8"
          ariaLabel={intl.formatMessage({ id: 'news.teaser.headline' })}
        >
          {intl.formatMessage({ id: 'news.teaser.headline' })}
        </Headline>
        <div className="md:flex md:space-x-2">
          <IconTeaser
            className="mb-7 md:w-1/3"
            icon="faq"
            link={intl.formatMessage({ id: 'path.faq' })}
            headline={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.1.headline'
            )}
            copy={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.1.copy'
            )}
          />
          <IconTeaser
            className="mb-7 md:w-1/3"
            icon="docs"
            link={intl.formatMessage({ id: 'path.downloads' })}
            headline={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.2.headline'
            )}
            copy={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.2.copy'
            )}
          />
          <IconTeaser
            className="md:w-1/3"
            icon="media"
            link={intl.formatMessage({ id: 'path.media' })}
            headline={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.3.headline'
            )}
            copy={getStaticFieldValue(
              'newsroom.iconteaser',
              'newsroom.iconteaser.3.copy'
            )}
          />
        </div>
      </Container>

      {events && (
        <Container className="mb-15" nopadding>
          <Headline
            level={3}
            className="mb-8"
            ariaLabel={intl.formatMessage({ id: 'news.events.headline' })}
          >
            {intl.formatMessage({ id: 'news.events.headline' })}
          </Headline>
          <TeaserCardList
            className={'lg:w-8/12 lg:mx-auto'}
            teaserList={events}
            separator
            button={{
              link: intl.formatMessage({ id: 'path.events' }),
              label: intl.formatMessage({ id: 'button.allevents' }),
            }}
          />
        </Container>
      )}
    </>
  )
}

Newsroom.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Newsroom

export const pageQuery = graphql`
  query NewsPageQuery($locale: String!) {
    contentfulEventList(
      node_locale: { eq: $locale }
      identifier: { eq: "events" }
    ) {
      events {
        title
        slug
        createdAt
        dateTimeStart
        dateTimeEnd
        copy {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        shortLocation
      }
    }

    allContentfulArticleCategory(
      filter: {
        node_locale: { eq: $locale }
        article: {
          elemMatch: {
            article___list: { elemMatch: { identifier: { eq: "newsroom" } } }
          }
        }
      }
    ) {
      nodes {
        id
        title
      }
    }

    contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "newsroom" }
    ) {
      articleHero {
        createdAt
        stageImage {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        title
        category {
          title
          id
        }
        slug
      }
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        category {
          title
        }
      }
    }
  }
`
