import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import TeaserCard from '@objects/teaserCard'
import Button from '@objects/button'

const StyledSpacer = styled.hr.attrs({
  className: 'w-full h-9 md:h-0 md:my-5 border-0 md:border-t',
})`
  border-color: ${({ theme }) => theme.colors['gray-light'][650]};
`

function TeaserCardList({ className, teaserList, separator, button, options }) {
  function renderTeaser() {
    return teaserList.map((teaserItem, i) => {
      return (
        <Fragment key={i}>
          {separator && i > 0 && <StyledSpacer />}
          <TeaserCard reverse={i % 2 ? true : false} {...teaserItem} />
        </Fragment>
      )
    })
  }

  return (
    <>
      <div data-testid="list" className={className}>
        {renderTeaser()}
      </div>
      {button && (
        <div className="text-center mt-9">
          <Button primary={options?.primary} to={button.link}>
            {button.label}
          </Button>
        </div>
      )}
    </>
  )
}

TeaserCardList.propTypes = {
  className: PropTypes.string,
  teaserList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.object,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string,
      duration: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      location: PropTypes.string,
    })
  ).isRequired,
  separator: PropTypes.bool,
  button: PropTypes.object,
  options: PropTypes.object,
}
export default TeaserCardList
